import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Link } from "gatsby";
import coursesStyles from "./courses.module.scss";

const Courses = ({cardSize, }) => {

    const data = useStaticQuery (graphql`
    query {
        allContentfulCourses {
            edges{
                node {
                    name
                    slug
                    type
                    image {
                        file {
                            fileName
                            url
                        }
                    }
                }
            }
        }
    }
    `)

    return (
        <div className={coursesStyles.container}>
            <div>
                <ol className={coursesStyles.cards}>
                    {data.allContentfulCourses.edges.map((edge) => {
                        return (
                            <li className={(cardSize == 2) ? coursesStyles.cardItemHome : coursesStyles.cardItem}>
                                <div className={coursesStyles.card}>
                                    <Link className={coursesStyles.link} to={`/course/${edge.node.slug}`}>
                                        <div className={coursesStyles.postImage}>
                                            <img className={coursesStyles.image} src={edge.node.image.file.url} alt={edge.node.image.file.fileName} />
                                        </div>
                                        <div className={coursesStyles.postHeader}>
                                            <div className={coursesStyles.postInfo}>
                                                <p className={coursesStyles.category}>{edge.node.type}</p>
                                                <h2>{edge.node.name}</h2>
                                            </div>    
                                            <button className={coursesStyles.details}>View details</button>
                                        </div>
                                    </Link>
                                </div>
                            </li>
                        )
                    })}
                </ol>
            </div>
        </div>
    )

} 


export default Courses;