import React from "react";
import Layout from "../components/layout";
import Courses from "../components/courses";
import Content from "../components/content";


const CoursesPage = () => {
  return (
    <Layout>
        <Content>
          <h1>Courses</h1>
          <Courses
          cardItems="3" />
        </Content>
    </Layout>
  )
}

export default CoursesPage;